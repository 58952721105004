import "../legal.css";

import * as React from "react";
import { useTranslation } from "react-i18next";
// import { Trans } from "react-i18next"
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
// import { Parallax } from "react-scroll-parallax";

import Layout from "../../../components/layout/Layout";
import Seo from "../../../components/seo";
import SectionLanding from "../../../components/section/SectionLanding";
import Mask5 from "../../../svg/masks/mask5.svg";
import { Link } from "gatsby-plugin-react-i18next";
export default function IndexPage() {
	const { t } = useTranslation();
	return (
		<Layout>
			<Seo title={t("Política de privacidad")} />
			<StaticImage
				src={`../../../images/contacto.jpg`}
				className="landing-bg"
				alt="Section background"
				layout="fullWidth"
			/>
			{/* <Parallax speed={-10} className="parallaxImg">
				<StaticImage
					src={`../../../images/contacto.jpg`}
					className="landing-bg"
					alt="Section background"
					layout="fullWidth"
				/>
			</Parallax> */}
			<Mask5 />

			<SectionLanding title={t("Política de privacidad")} />

			<div className="legalBody section invertColors" id="proteccionDatos">
				<h2>1. Objeto</h2>
				<p>
					Esta Política de Privacidad tiene por objeto dar a conocer a las
					personas (en adelante, usuarios o interesados) que visiten nuestra
					página Web (en adelante, página web, sitio web o la web), el modo en
					que recabamos, tratamos y protegemos los datos personales que decida
					facilitarnos por cualquier medio (formularios, correos electrónicos,
					teléfono, contratos, etc.) y tras su lectura, decida libremente si
					desea que los tratemos. Adicionalmente, servirá para ampliar la
					información que previamente le hemos proporcionado a los interesados,
					en las cláusulas informativas dispuestas en los procesos de recogida
					de sus datos personales.
					<br />
					Asimismo, esta política pretende cumplir con el Reglamento (UE)
					2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016
					relativo a la protección de las personas físicas en lo que respecta al
					tratamiento de datos personales y a la libre circulación de estos
					datos (en adelante RGPD) y a la Ley Orgánica 3/2018, de 5 de
					diciembre, de Protección de Datos Personales y garantía de los
					derechos digitales (en adelante, LOPDGDD).
				</p>
				<h2>
					2. ¿Quién es el responsable del tratamiento de sus datos personales?
				</h2>
				<p>
					Identidad - Entidad : BEGIN RESTAURANTES, S.L.
					<br />
					CIF/NIF: B40626392
					<br />
					Dirección postal: Av. Cortes Valencianas, 50, CP 46015 Valencia
					(Valencia)
					<br />
					Correo electrónico: rrhh@beginrestaurante.com
					<br />
					Objeto Social: Restauración
					<br />
					Sitio web: https://beginrestaurante.com/
					<br />
					Datos registrales: Inscrita en el Registro Mercantil de Valencia, Tomo
					10754, Libro 8034, Folio 132, Sección 8, Hoja 190498, Inscripción 11.
					<br />
				</p>

				<h2>3. ¿Qué datos personales trataremos y cómo los obtenemos?</h2>
				<p>
					Para el desarrollo de nuestra actividad empresarial, es imprescindible
					tratar datos personales cuya recopilación se puede realizar por medios
					digitales, (ej. correo electrónico, formularios o cuestionarios web),
					<br />
					mediante cumplimentación de documentos en papel, o mediante
					conversaciones presenciales o telefónicas y en cualquiera de estos
					casos los datos serán tratados de forma leal, lícita y transparente.
					<br />
					Las categorías de datos que nuestra entidad tratará sobre los
					interesados son: <br />
					• Datos identificativos: nombre y apellidos, DNI o documento
					equivalente, imagen y firma (manuscrita o digital). <br />
					• Datos de contacto: teléfono, email, dirección postal. <br />
					• Datos comerciales: histórico de reservas. <br />• Datos contables:
					control de ingresos y gastos, datos de facturación. <br />
					• Datos bancarios: cuentas y tarjetas bancarias. <br />
					• Transacción de bienes y servicios: transferencias y domiciliaciones
					bancarias, importes y conceptos. <br />
					• Datos financieros: condiciones de pago, gestión de cobros. <br />
					• Datos de navegación: análisis del tiempo de estancia en nuestra web,
					páginas visitadas, datos demográficos (ej. edad, sexo). <br />
				</p>

				<p>
					Nuestra entidad no recopilará datos de categoría especial (ej. datos
					de salud, origen étnico, opiniones políticas o creencias religiosas),
					pero en el caso de fuera necesario tratarlos, le informará y le
					solicitará consentimiento previo y expreso.
					<br /> En consecuencia, los datos solicitados serán adecuados,
					pertinentes, limitados a los estrictamente imprescindibles y
					necesarios, tratados únicamente por el personal y/o colaboradores
					autorizados por nuestra entidad, que habrán firmado un compromiso de
					confidencialidad y se comprometen a cumplir con las normas de
					seguridad necesarias que garanticen la confidencialidad, integridad y
					disponibilidad de los datos tratados y demás requisitos legalmente
					establecidos en el RGPD. Por tanto, serán tratados dentro de la
					legalidad.
					<br />
					Los datos a tratar son facilitados por el propio interesado o por su
					representante legal y siempre serán tratados con su consentimiento
					previo y expreso.
					<br />
					En el caso de que un interesado no facilite los datos que le
					solicitamos o se proporcionen datos incompletos o incorrectos, no será
					posible cumplir y mantener la relación con estos.
				</p>

				<p>
					Las categorías de datos que podamos tratar sobre una persona, irán en
					función de la relación que mantenga con nuestra entidad, según
					mostramos a continuación: <br />
					<u>Clientes</u>: Se tratarán datos de carácter identificativo, de
					contacto, comerciales, contables, bancarios, transacción de bienes y
					servicios, financieros, y podrán ser recabados solo si usted nos los
					proporciona en el momento de la reserva de mesa o para la elaboración
					de facturas.
					<br />
					<u>Solicitantes de información</u>
					: Tanto si la información solicitada es telefónica o por escrito (ej.
					correo electrónico o formularios web) le solicitaremos y trataremos
					sus datos identificativos, de contacto y datos comerciales.
					<br />
					<u>Proveedores y colaboradores</u>
					: Se tratarán datos de identificación, de contacto, comerciales,
					contables, bancarios, transacción de bienes y servicios y financieros.
					Estos datos podrán ser tratados durante todas las etapas de la
					relación comercial.
					<br />
					<u>Solicitantes de empleo</u>: Para esta categoría de interesados, se
					tratarán datos curriculares, identificativos, de contacto y otros
					relacionados con sus características profesionales o personales, al
					hacernos llegar su candidatura por cualquier medio (ej.
					presencialmente, correo electrónico, formularios web), también pueden
					ser recopilados en las entrevistas de selección de personal
					(presenciales o por videoconferencia), incluso puede llegarnos su
					solicitud de empleo mediante algún colaborador al que le hayamos
					delegado ciertas funciones. Para más información consulte nuestra{" "}
					<Link to="/legal/politica-empleo">
						Política de solicitantes de empleo
					</Link>
					.
					<br /> <u>Usuarios de redes sociales</u>: Estamos presentes en
					diferentes redes sociales y podremos tratar datos identificativos, de
					contacto, comerciales y otros datos que el usuario habilite para que
					sean visualizados o compartidos con el resto de usuarios de la red
					social, incluso datos curriculares (ej. LinkedIn). Para más
					información consulte nuestra{" "}
					<Link to="/legal/politica-RRSS">Política de redes sociales</Link>.
					<br /> <u>Colaboradores</u>
					: Se tratarán datos de identificación, de contacto, comerciales,
					contables, bancarios, transacción de bienes y servicios y financieros.
					Estos datos podrán ser tratados durante todas las etapas de la
					relación comercial.
					<br />
					<u>Reclamantes</u>
					: Se tratarán datos de carácter identificatorio, de contacto e
					información personal propia o de terceros que quiera hacernos saber
					relacionada con la reclamación que nos remita.
					<br />
					<u>Denunciantes</u>
					: Mediante nuestro canal interno de denuncias, se podrán formular
					denuncias de forma anónima, pero también podrá proporcionarnos
					voluntariamente datos identificativos y de contacto, así como otra
					información personal propia o de terceros relacionados con la
					denuncia, según lo dispuesto en la Ley 2/2023, de 20 de febrero, de
					protección de las personas que informen sobre infracciones normativas
					y de lucha contra la corrupción. Más información en los términos del
					Canal interno de denuncias.
					<br />
					<u>Usuarios web</u>: Al visitar nuestra web y solo si el usuario lo
					autoriza expresamente, se podrán recopilar datos analíticos (ej.
					tiempo de visita o páginas vistadas) incluso datos demográficos (ej.
					sexo, edad, país o idioma). Para más información visitar nuestra{" "}
					<Link to="legal/politica-cookies">Política de Cookies</Link>.
					<br />
					<u>Más información para los interesados</u>
					: Se pondrá a disposición de los interesados, la información
					legalmente establecida en las correspondientes cláusulas informativas
					incluidas en los diferentes medios de recogida de datos, de forma que
					usted decida libre y expresamente si quieren que los datos personales
					solicitados, sean tratados por nuestra entidad.
					<br />
					Todas las categorías y tipo de datos personales tratados, quedarán
					debidamente identificados en las correspondientes actividades de
					tratamiento titularidad de nuestra entidad.
				</p>

				<h2>4. ¿Para qué serán tratados sus datos?</h2>
				<p>
					Con carácter general, el tratamiento de datos personales realizado por
					nuestra entidad tiene como finalidad el cumplimiento y mantenimiento
					de la relación con diferentes colectivos de personas, como por
					ejemplo, clientes o proveedores. También con otras personas que nos
					contacten proactivamente por medio de nuestros formularios web,
					telefónica o personalmente, por correo electrónico o postal, como
					sería el caso de los solicitantes de empleo o solicitantes de
					información, los usuarios de nuestra web/blog o redes sociales e
					interesados en general.
					<br />
					Dependiendo de dicha relación el tratamiento de sus datos obedece a
					diferentes finalidades y que con carácter enunciativo y no limitativo,
					detallamos a continuación: <br />
					<u>Clientes</u>: Sus datos personales serán tratados para
					identificarle, cumplir y mantener la relación precontractual y
					contractual incluyendo el envío de comunicaciones comerciales por
					diferentes medios, atender consultas, realización de controles de
					calidad y estadísticas comerciales, celebrar sorteos y entrega de
					premios, para la gestión contable y de facturación, la transacción de
					bienes y servicios, la gestión de cobros, gestión de P á g i n a 23 |
					29 incidencias, reclamaciones y ejercicio de derechos, así como para
					otros fines a los que nos veamos obligados para dar cumplimiento a
					dicha relación, a las leyes a las que estamos sujetos y para cumplir
					con nuestros intereses legítimos.
					<br />
					<u>Solicitantes de información</u>
					: Trataremos sus datos personales para atender sus solicitudes de
					información general, para identificarle, para el envío o entrega de
					presupuestos e información sobre los bienes y servicios de su interés,
					incluyendo en nuestra contestación (verbal, escrita o digital) la
					información comercial relacionada con la solicitud. También
					realizaremos contactos de seguimiento, por diferentes medios para
					conocer las decisiones tomadas respecto a las propuestas comerciales
					que le hayamos remitido.
					<br />
					<u>Proveedores y colaboradores</u>
					: Se tratarán sus datos personales con la finalidad de mantener la
					relación comercial ya sea para la solicitud de presupuestos, para la
					compra de bienes o contratación de servicios, para identificarle, para
					la gestión contable, realizar transacción de bienes y servicios, así
					como para otros fines necesarios para cumplir con dicha relación, con
					nuestras obligaciones legales e intereses legítimos.
					<br />
					<u>Solicitantes de empleo</u>: Sus datos se tratarán para incluirle en
					nuestros procesos de selección y bolsa de trabajo, para identificarle,
					así como para contactarle e informarle sobre vacantes, coordinación de
					entrevistas y otros asuntos relacionados con su candidatura. Para más
					información consulte nuestra{" "}
					<Link to="/legal/politica-empleo">
						Política de solicitantes de empleo
					</Link>
					.
					<br /> <u>Usuarios de redes sociales</u>: Trataremos sus datos
					personales para mantener la relación como usuarios de la misma red
					social, para identificarle, para contactarle, compartir noticias y
					otros datos personales que usted permita compartir con el resto de
					componentes de la red social. Para más información consultar nuestra
					<Link to="/legal/politica-RRSS">Política de redes sociales</Link>.
					<u>Reclamantes</u>
					: Los datos personales serán tratados para identificarle, gestionar su
					reclamación y contactarle sobre la situación de esta, además de
					cumplir con nuestras obligaciones legales e intereses legítimos.
					<br />
					<u>Denunciantes</u>
					: Los datos personales que decida proporcionarnos en su denuncia,
					serán tratados para registrar, gestionar su denuncia, así como para
					identificarle y contactarle (excepto si la denuncia es anónima) para
					el acuse de recibo de su denuncia y para mantenerle informado sobre la
					situación de nuestras investigaciones en los plazos y términos
					establecidos en la Ley 2/2023, de 20 de febrero. Del mismo modo,
					podremos tratar sus datos con fundamento en nuestros intereses
					legítimos y siempre que sea necesario cumplir con otras obligaciones
					legales a las que estemos sujetos. Más información en los términos del
					Canal interno de denuncias.
					<br />
					<u>Usuarios web</u>: También se pueden tratar datos con diferentes
					finalidades (ej. análisis de las visitas) al aceptar la instalación de
					cookies cuando se visita nuestra web. Para más información visitar
					nuestra <Link to="legal/politica-cookies">Política de Cookies</Link>.
					<br />
					<u>Más información para los interesados</u>: Se pondrá a disposición
					de los interesados, la información legalmente establecida en las
					correspondientes cláusulas informativas incluidas en los diferentes
					medios de recogida de datos, (ej. formularios, locuciones, contratos,
					etc.) y en otros que pondremos a su disposición (ej. distintivos,
					facturas, avisos legales, etc.), de forma que usted decida libre y
					expresamente si quieren que los datos personales solicitados sean
					tratados por nuestra entidad.
					<br />
				</p>
				<p>
					En caso de que usted no facilite los datos que le solicitemos o se
					proporcionen datos incompletos o erróneos, no será posible atender su
					solicitud de información, compraventa de bienes o la contratación de
					nuestros servicios.
					<br />
					Los datos no serán tratados ulteriormente o con finalidades distintas
					a las aceptadas por los interesados.
					<br />
					Las finalidades que motivan el tratamiento de datos personales
					quedarán debidamente identificadas en las correspondientes actividades
					de tratamiento titularidad de nuestra entidad.
				</p>

				<h2>5. ¿Por qué tratamos sus datos (legitimación)?</h2>
				<p>
					El tratamiento de sus datos personales por parte de nuestra entidad,
					se realiza con alguna o varias de las siguientes bases legitimadoras:{" "}
					<br />
					a. Cuando nos ofrece su consentimiento expreso, libre, informado e
					inequívoco, tras ser informado en el momento de recoger sus datos y de
					forma más ampliada con la presente política de privacidad, que tras la
					lectura de esta y de estar conforme, usted puede voluntariamente
					autorizarnos al tratamiento de sus datos para uno o varios fines,
					mediante la marcación de las casillas dispuestas a tal efecto en
					nuestros formularios web, mediante la firma de las cláusulas
					informativas que le proporcionemos en cada momento al solicitarle sus
					datos personales. <br />
					b. Para la ejecución de un contrato en el que usted sea parte o nos
					haya solicitado medidas precontractuales. <br />
					c. Cuando el tratamiento sea necesario para el cumplimiento de una
					obligación legal aplicable a nuestra entidad. <br />
					a. Cuando el tratamiento es necesario para la satisfacción de
					intereses legítimos perseguidos por nuestra entidad o por un tercero,
					siempre que sobre dichos intereses no prevalezcan los intereses o los
					derechos y libertades fundamentales del interesado. A este respecto,
					informamos que nuestra entidad ha realizado un análisis ponderando
					nuestros intereses legítimos con los derechos y libertades del
					interesado, respetando siempre sus derechos fundamentales. <br />
					En el caso de que el usuario sea menor de 14 años, será necesario
					contar con el consentimiento de los padres, tutores o representante
					legal, para tratar sus datos. El usuario es el único responsable de la
					veracidad de los datos que nos remite.
				</p>

				<h2>6. Conservación de datos</h2>
				<p>
					Los datos personales proporcionados se conservarán mientras
					mantengamos la relación con usted y durante el tiempo necesario para
					cumplir con la finalidad para la que se han recabado sus datos.
					Finalizada dicha relación los mantendremos bloqueados en aquellos
					casos que sea necesario conservarlos hasta la prescripción de
					responsabilidades a los efectos exclusivos de reclamaciones o acciones
					legales, así como para cumplir con nuestras obligaciones legales, por
					ejemplo:{" "}
					<table>
						<thead>
							<tr>
								<th>Interesados</th>
								<th>Ambito sectorial</th>
								<th>Base legal</th>
								<th>Plazo de conservacion</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td rowspan="2">
									Clientes
									<br />
									Proveedores
								</td>
								<td>Contabilidad</td>
								<td>Art. 30.1 R.D. Código de Comercio</td>
								<td>6 años a partir del último asiento</td>
							</tr>
							<tr>
								<td>Fiscal</td>
								<td>Art. 66 Ley General Tributaria 58/2003</td>
								<td>
									Plazo general: 4 años
									<br />
									En caso de pérdidas durante el ejercicio: 10 años
									<br />
									Facturas: 5 años
								</td>
							</tr>
							<tr>
								<td>Personas trabajadoras</td>
								<td>Laboral</td>
								<td>
									Art. 21 del Real Decreto Legislativo 5/2000 – Orden Social
								</td>
								<td>4 años</td>
							</tr>
							<tr>
								<td>Solicitantes de empleo</td>
								<td>Laboral</td>
								<td>Guía de relaciones laborales</td>
								<td>1 año</td>
							</tr>
							<tr>
								<td>Personas trabajadoras</td>
								<td>Prevención de riesgos laborales</td>
								<td>Art. 4.3 del Real Decreto 5/2000 – Orden Social</td>
								<td>5 años</td>
							</tr>
							<tr>
								<td>
									Clientes
									<br />
									Proveedores
									<br />
									Visitantes
									<br />
									Solicitantes de empleo
									<br />
									Personas trabajadoras
								</td>
								<td>Videovigiilancia</td>
								<td>Art. 22.3 LOPDGDD – protección de datos personales</td>
								<td>1 mes</td>
							</tr>
							<tr>
								<td>
									Denunciantes
									<br />
									Afectados
								</td>
								<td>Denuncias del Canal Interno de Denuncias</td>
								<td>
									Art. 26.2 Ley 2/2023 – sistemas de información interna
									<br />
									Art. 32.3 Ley 2/2023 – sistemas de información interna
									<br />
									Art. 32.4 Ley 2/2023 – sistemas de información interna
								</td>
								<td>
									En caso de que los hechos denunciados se hayan investigado, no
									superará los 10 años de conservación.
									<br />
									Durante el tiempo imprescindible para decidir sobre la
									procedencia de iniciar una investigación sobre los hechos
									informados. Si se acredita que la información facilitada o
									parte de ella no es veraz, deberá procederse a su inmediata
									supresión, salvo que dicha falta de veracidad pueda constituir
									un ilícito penal, en cuyo caso se guardará la información por
									el tiempo necesario durante el que se tramite el procedimiento
									judicial.
									<br />
									Si pasados los 3 meses de la recepción de la denuncia no se
									iniciase la investigación, deberán suprimirse.
								</td>
							</tr>
							<tr>
								<td>Solicitantes de empleo</td>
								<td>Solicitudes de empleo</td>
								<td>Guía sobre las relaciones laborales - AEPD</td>
								<td>1 año</td>
							</tr>
						</tbody>
					</table>
				</p>

				<h2>7. Elaboración de perfiles</h2>
				<p>
					No elaboramos perfiles ni se realizarán decisiones automatizadas
					utilizando sus datos personales, pero en el caso de hacerlo se le
					informará y solicitará autorización previa para hacerlo. <br /> Del
					mismo modo, tiene el derecho a oponerse a este tipo de tratamiento en
					cualquier momento dirigiéndose por escrito a nuestra entidad a{" "}
					<a href="mailto:contacto@beginrestaurante.com">
						contacto@beginrestaurante.com
					</a>
					.
				</p>

				<h2>8. Cesión de datos</h2>
				<p>
					Por norma general nuestra entidad no cede datos personales a terceros,
					aunque se puede dar el caso donde esto sea necesario. Por ejemplo:
				</p>

				<p>
					Si es usted cliente o proveedor, sus datos personales podrán ser
					cedidos a entidades terceras por obligación legal (ej. Agencia
					Tributaria), o en aquellos casos y entidades necesarias para prestarle
					nuestros servicios o pagar facturas (ej. Entidades bancarias), o en el
					caso de entrega de comida a domicilio sus datos podrán ser cedidos a
					empresas colaboradoras de nuestra entidad, necesario para que reciba
					su pedido.
				</p>
				<p>
					Asimismo, sus datos personales en calidad de cliente o proveedor
					podrán ser tratados por ciertos proveedores a los que delegamos alguna
					de nuestras obligaciones (ej. asesores contables) y todos ellos se han
					comprometido mediante un contrato de encargado del tratamiento a
					cumplir con las mismas medidas de seguridad implantadas por nuestra
					entidad, así como a someterse al deber de secreto y confidencialidad
					sobre los datos personales tratados, entre otras obligaciones en
					materia de protección de datos personales.
				</p>
				<p>
					Si es un solicitante de empleo, sus datos no se cederán a entidades
					terceras, salvo que legalmente nos veamos obligados a ello.
				</p>
				<p>
					Si es usted un solicitante de información o usuario de nuestro sitio
					web, sus datos no se cederán a entidades terceras, salvo que
					legalmente nos veamos obligados a ello.
				</p>
				<p>
					En términos generales podremos ceder sus datos personales a los
					Jueces, Tribunales, Ministerio Fiscal y/o a las Administraciones
					Públicas competentes ante posibles reclamaciones cuando estemos
					obligados a ello.
				</p>

				<h2>9. Transferencia internacional de datos</h2>
				<p>
					En caso de cesiones a entidades terceras ubicadas en países fuera del
					Espacio Económico Europeo, le informaremos y solicitaremos su
					consentimiento previo y expreso.
				</p>

				<h2>10. Medidas de Seguridad</h2>
				<p>
					Nuestra entidad ha implantado todas las medidas técnicas y
					organizativas necesarias para proteger los datos personales tratados,
					evitando su pérdida, robo o uso no autorizado.{" "}
				</p>
				<p>
					Dichas medidas se han creado en función del tipo de datos tratados y
					las finalidades que motivan dicho tratamiento. Estas son verificadas
					periódicamente en nuestros controles internos de cumplimiento de la
					normativa de protección de datos personales y mediante auditorías
					externas.
				</p>

				<h2>11. Sus Derechos</h2>
				<p>
					Usted, como titular de sus datos personales y actuando en su propio
					nombre o por medio de su representante legal (ej. personas menores de
					14 años) puede dirigirse a nuestra entidad en cualquier momento y
					solicitarnos ejercer sus derechos en materia de protección de datos
					personales. <br />
					Le explicamos cuales son estos derechos:
				</p>

				<p>
					<u>Derecho de Acceso</u>:<br />
					Usted tiene derecho a conocer y solicitarnos en cualquier momento
					conocer la información siguiente: <br />
					• Si estamos tratando o no sus datos personales. <br />
					• Los fines del tratamiento, así como las categorías de datos
					personales que se traten. <br />
					• El origen de sus datos, en caso de que no nos los proporcionara
					usted. <br />• Los destinatarios o categorías de destinarios a los que
					se han comunicado mis datos personales, o serán comunicados,
					incluyendo, en su caso, destinatarios en terceros u organizaciones
					internacionales. <br />
					• Información sobre las garantías adecuadas relativas a la
					transferencia de mis datos a un tercer país o a una organización
					internacional, en su caso. <br />
					• El plazo previsto de conservación, o de no ser posible, los
					criterios para determinar este plazo. <br />
					• Si existen decisiones automatizadas, incluyendo la elaboración de
					perfiles, información significativa sobre la lógica aplicada, así como
					la importancia y consecuencias previstas de dicho tratamiento. <br />
					• Copia de sus datos personales que son objeto de tratamiento. <br />
				</p>

				<p>
					<u>Derecho de Rectificación</u>: <br />
					Solicitarnos la rectificación de sus datos personales cuando éstos
					sean inexactos, así como a completarlos cuando éstos sean incompletos.
				</p>
				<p>
					<u>Derecho de Oposición</u>: <br /> Usted puede oponerse a que
					tratemos sus datos cuando estos sean incorrectos o ya no sea necesario
					su tratamiento. En el caso que usted actúe en calidad de denunciado o
					persona afectada por una denuncia en el marco de la Ley 2/2023, no
					podrá ejercer su derecho de oposición, ya que se presume (bajo prueba
					en contrario) que existen motivos que legitiman el tratamiento de sus
					datos personales, de acuerdo con lo dispuesto en el artículo 31.4 de
					la Ley.
				</p>
				<p>
					<u>Derecho de Supresión</u>: <br /> Solicitarnos que sus datos sean
					eliminados, por alguna de estas causas: <br />• Sus datos ya no sean
					necesarios para los fines para los que fueron recogidos o tratados.{" "}
					<br />• Usted no haya dado el consentimiento para el tratamiento de
					sus datos.
					<br />• Cuando haya ejercido el derecho de oposición. <br />• Cuando
					los datos hayan sido tratados ilícitamente. <br />• Cuando los datos
					deban suprimirse para el cumplimiento de una obligación legal.
				</p>
				<p>
					<u>Derecho a la Limitación del tratamiento</u>: <br />
					Podrá solicitarnos ejercer este derecho cuando se dé alguno o varios
					de estos supuestos: <br />• Cuando usted impugne la exactitud de sus
					datos, durante un plazo que permita al responsable verificar la
					exactitud de estos. <br />• Cuando el tratamiento sea ilícito y usted
					se oponga a la supresión de sus datos y solicite en su lugar la
					limitación de su uso. <br />• Cuando ya no se necesiten los datos para
					los fines del tratamiento, pero el interesado los necesite para la
					formulación, el ejercicio o la defensa de reclamaciones. <br />•
					Cuando usted se haya opuesto al tratamiento en virtud del artículo 21,
					apartado 1, mientras se verifica si los motivos legítimos del
					responsable prevalecen sobre los del interesado.
				</p>
				<p>
					<u>Derecho de Portabilidad</u>: <br /> Se refiere al derecho a obtener
					los datos relacionados con usted, en un formato estructurado, de uso
					común y lectura mecánica, así como transmitirlos a otro responsable
					para su ulterior tratamiento.
				</p>
				<p>
					<u>Derecho a no ser objeto de decisiones automatizadas</u>: <br />{" "}
					Derecho a no ser objeto de una decisión basada únicamente en el
					tratamiento automatizado, incluida la elaboración de perfiles, que
					produzca efectos jurídicos en él o le afecte significativamente de
					modo similar.
				</p>

				<p>
					Para ejercer cualquiera de sus derechos, deberá dirigirse por escrito
					a nuestra entidad bien por correo postal a la dirección: Av. Cortes
					Valencianas, 50, CP 46015 Valencia o al correo electrónico:{" "}
					<a href="mailto:contacto@beginrestaurante.com">
						contacto@beginrestaurante.com
					</a>
					, manifestando los derechos que desea ejercer, acompañado de una copia
					de su DNI o documento equivalente para saber sobre quien debemos
					aportar la información solicitada y sus datos de contacto para
					remitirle nuestra contestación. Si actúa en representación de otra
					persona, deberá acreditar su representación.
				</p>

				<p>
					Si desea trasladar alguna sugerencia o consulta sobre el tratamiento
					de sus datos personales, puede contactar con nuestros consultores de
					protección de datos, indicando el nombre de nuestra empresa o el
					nombre comercial: <br />
					BUSINESS ADAPTER, S.L. <br />
					Ronda Guglielmo Marconi, 11, 26, (Parque Tecnológico) 46980 Paterna
					(Valencia). <br />
					<a href="https://www.businessadapter.es/contacta-con-el-delegado-de-proteccion-de-datos">
						Formulario de atención al interesado
					</a>
				</p>

				<p>
					Le informamos que si usted en tiende que sus derechos han sido
					desatendidos, tiene derecho a realizar una reclamación ante la Agencia
					Española de Protección de Datos en: C/ Jorge Juan, 6, 28001 Madrid o
					en <a href="www.aepd.es">www.aepd.es</a>.
				</p>

				<h2>12. Compromiso con la Protección de datos personales</h2>
				<h3>Ámbito de aplicación</h3>
				<p>
					Nuestro compromiso con la protección de datos personales, será de
					obligado cumplimiento para todos los departamentos y personas
					trabajadoras de nuestra entidad, así como aquellos terceros que actúen
					en nuestro nombre.
				</p>

				<h3>Objeto</h3>
				<p>
					Hemos establecido unos protocolos de actuación para el tratamiento de
					sus datos personales, de acuerdo con lo dispuesto en la normatividad
					europea y española de protección de datos.
				</p>

				<h3>Principios</h3>
				<p>
					Trataremos sus datos con licitud, lealtad, transparencia, minimización
					de los datos, exactitud, limitación del plazo de conservación,
					integridad, confidencialidad y responsabilidad activa.
				</p>

				<h3>Categoría especial de datos</h3>
				<p>
					Nuestra entidad prohíbe el tratamiento de datos personales que revelen
					el origen étnico o racial, las opiniones políticas, las convicciones
					religiosas o filosóficas, la afiliación sindical, el tratamiento de
					datos genéticos o biométricos, datos relativos a la salud o datos
					relativos a la orientación sexual, salvo en las excepciones
					autorizadas legalmente y con el consentimiento previo del interesado.
				</p>

				<h3>Derechos de los interesados</h3>
				<p>
					Nuestra entidad atenderá y responderá con la mayor rapidez y
					diligencia posible, sus solicitudes de ejercicio de derechos.
				</p>

				<h3>
					Registro de Actividades, Evaluación de Impacto y Medidas de Seguridad
				</h3>
				<p>
					Nuestra entidad llevará a cabo un registro de las actividades de
					tratamiento y analizará los fines del tratamiento, categorías de los
					interesados y de datos, destinatarios, transferencias internacionales,
					plazos de conservación, etc., para evaluar los riegos del tratamiento
					e implantar las medidas de seguridad necesarias para garantizar la
					confidencialidad, integridad y disponibilidad de los datos personales.
				</p>
				<p>
					Asimismo, en cada actividad de tratamiento se han analizado la
					necesidad de elaborar una Evaluación de Impacto y determinar si existe
					una obligación de designar un Delegado de Protección de Datos,
					estableciendo en caso de ser necesario, que la persona designada para
					este puesto cumpla con los conocimientos y experiencia suficientes
					atendiendo a lo establecido en la normativa vigente.
				</p>

				<h3>Control</h3>
				<p>
					Contamos con ayuda externa que nos asesora en esta materia,
					monitorizando todas las publicaciones realizadas por los órganos de
					control competentes y otras entidades europeas y españolas
					relacionadas con la normativa de protección de datos, con el fin de
					cumplir con esta normativa en todo momento.
				</p>

				<h2>13. Actualización de esta Política</h2>
				<p>
					Nuestra entidad se reserva el derecho a la modificación de esta
					Política sin previo aviso. Es por ello, que recomendamos consultarla
					cada vez que visite nuestra web.
				</p>

				<p style={{ textAlign: "right" }}>
					Actualizado el 6 de octubre de 2023.
				</p>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
